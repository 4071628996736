export default {
	lng: 'vi', //vi|en
	region: 'VN', //vi|pk
	version: '1',
	domain: 'bilac.fconline.garena.vn',
	demo: 0,
	demoMsg: 'Sự kiện sẽ bắt đầu vào ngày 09.05.2024',
	baseUrl: `https://bilac.fconline.garena.vn`,
	shareHashtag: 'BiLacFCO',
	shareQuote:
		'Quẩy bi lắc cực hay, săn ngay dàn sao 24TOTS, CU và ngập tràn FC thôi nào anh em ơi!!',
	predictGuide: 'https://',
	spinGuide:
		'https://cdn.vn.garenanow.com/web/fo4vn/Khoa/Aug2021/eacc/tichdiem_eacc.png',
	prizeList:
		'https://cdn.vn.garenanow.com/web/fo4vn/Khoa/Aug2021/eacc/qua_ranking.png',
	prizeListGlobal:
		'https://cdn.vn.garenanow.com/web/fo4vn//Khoa/2022/T4/eacc/eacc_qua.png',
	guideImg:
		'https://cdn.vn.garenanow.com/web/fo4vn//Khoa/2022/T4/eacc/eacc_hd.png',
};
