import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import Scrollbar from 'react-scrollbars-custom';

Modal.setAppElement('#root');

const ModalGuide = ({ modalStatus, closeModal }) => {
	const afterOpenModal = () => {};

	const selfClose = (event) => {
		event.preventDefault();
		closeModal();
	};

	return (
		<Modal
			isOpen={modalStatus}
			onAfterOpen={afterOpenModal}
			onRequestClose={selfClose}
			contentLabel="Example Modal"
			portalClassName="ReactModalPortal"
			overlayClassName=""
			className="animated fadeInDown faster modal-guide modal-big modal-history"
		>
			<h2 className="text-neon">
				<img src="/images/title-rule.png" alt="" className="title-modal" />
			</h2>
			<div className="modal-description">
				<Scrollbar style={{ height: '35vw' }} noScrollX={true}>
					<div className="modal-guide--content text-left">
						<p>
							Sự kiện Bi lắc đã trở lại với sự xuất hiện của dàn siêu phẩm CC,
							LN, HG mạ vàng cùng ICON The Moment sở hữu bộ chỉ số cực khủng.
							Đặc biệt, chỉ với lượt chơi FREE mỗi ngày các HLV sẽ được săn dàn
							sao BLD, BDO cùng hàng nghìn FC cực giá trị đấy!
						</p>
						<br />
						<h3>1. THỜI GIAN DIỄN RA SỰ KIỆN:</h3>
						<ul>
							<li>Từ ngày 03.12 - 09.12.2024</li>
						</ul>
						<br />
						<h3>2. HƯỚNG DẪN CHƠI:</h3>
						<p>
							- Trong khung thành của bàn bi lắc sẽ chứa đựng 5 loại huy hiệu
							khác nhau, bao gồm:
						</p>
						<ul>
							<li>Huy hiệu Giải đặc biệt.</li>
							<li>Huy hiệu FC.</li>
							<li>Huy hiệu BP.</li>
							<li>Huy hiệu Ballon d’Or</li>
							<li>Huy hiệu Thẻ.</li>
						</ul>
						<p>
							Với mỗi lần chơi (bao gồm 3 cú sút vào khung thành), các HLV sẽ
							nhận về tổ hợp 3 huy hiệu ngẫu nhiên, dựa theo tổ hợp này, các HLV
							sẽ nhận về 1 vật phẩm tương ứng.
						</p>
						<p>
							Ngoài vật phẩm, các HLV cũng nhận về điểm Lucky Star, điểm Lucky
							Star sẽ là cơ sở để các HLV nhận quà tại các mốc tích lũy tăng
							dần.
						</p>
						<p>
							Lượt chơi miễn phí: Người chơi cần hoàn thành nhiệm vụ hằng ngày
							để lấy được lượt chơi miễn phí.
						</p>
						<ul>
							<li>
								Hoàn thành nhiệm vụ Thắng một trận bất kỳ (Không tính đá với bạn
								và mô phỏng).
							</li>
							<li>
								Lưu ý:
								<ul>
									<li>
										Phải ấn Nhận nhiệm vụ trước khi làm nhiệm vụ để hệ thống xác
										nhận cho bạn.
									</li>
									<li>Nhiệm vụ sẽ reset mỗi ngày</li>
								</ul>
							</li>
							<li>
								Ngoài ra, khi chia sẻ sự kiện, các HLV còn nhận thêm 1 lượt miễn
								phí nữa (1 lần duy nhất toàn sự kiện)
							</li>
						</ul>
						<p>Giải đặc biệt:</p>
						<ul>
							<li>
								Mỗi lượt chơi tại sự kiện sẽ tích lũy thêm 1 FC/MC vào Giải đặc
								biệt
							</li>
							<li>
								Nếu một HLV nhận được tổ hợp 3 huy hiệu Giải đặc biệt, HLV đó sẽ
								nhận 100% số FC/MC trong giải.
							</li>
							<li>
								Nếu một HLV nhận được tổ hợp 2 huy hiệu Giải đặc biệt + 1 huy
								hiệu bất kỳ, HLV đó sẽ nhận 10% số FC/MC trong giải
							</li>
						</ul>
						<p>* Lưu ý:</p>
						<ul>
							<li>Quà sẽ được gửi trong vòng 1 giờ.</li>
							<li>Ấn phím F5 để được cập nhật điểm FC/MC.</li>
							<li>Quà tích lũy sẽ được reset không giới hạn.</li>
							<li>
								Gói ICONTM & CC, LN, HG, RTN, SPL, LOL, FA, BTB 105+ (+8) là các
								HLV có thể mở ra ngẫu nhiên 1 cầu thủ ICON The Moment hoặc 1 cầu
								thủ CC, LN, HG, RTN, SPL, LOL, FA, BTB (+8).
							</li>
							<li>
								Để nhận được gói Gói ICON The Moment T11/2024 và Gói ICON The
								Moment 120+ các HLV cần phải đạt đủ mức điểm lần lượt là 7250 và
								29000 điểm Lucky Star.
							</li>
							<li>
								Để nhận được Gói LN chỉ định 70 (+8) và Gói CC 105+ (+8) các HLV
								cần phải đạt đủ mức điểm lần lượt là 10150 và 14500 điểm Lucky
								Star.
							</li>
						</ul>
					</div>
				</Scrollbar>
			</div>
			<a
				onClick={(event) => selfClose(event)}
				className="close"
				data-dismiss="modal"
				aria-label="Close"
			>
				×
			</a>
		</Modal>
	);
};

export default ModalGuide;
