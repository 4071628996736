import React, { useState, useEffect } from 'react';
import { NavLink, useHistory, useParams, useLocation } from 'react-router-dom';
import queryString from 'query-string';

import config from 'config/common';
import lib from 'lib/commons';

import ModalHistory from 'components/ModalHistory';
import ModalGuide from 'components/ModalGuide';

const Header = ({
	lng,
	user: {
		user: {
			nickname = '',
			id: userId = null,
			uid = null,
			total_spin_times: totalSpinNum = 0,
			vote_history: voteHistory = [],
		} = {},
		matches = [],
		userHistory = [],
		eventStarted,
		isGettingHistory,
		special_date = false,
	} = {},

	getHistory,
}) => {
	let history = useHistory();
	const [modalStatus, setModalStatus] = useState(false);
	const closeModal = () => setModalStatus(false);

	const [modalGuideStatus, setModalGuideStatus] = useState(false);
	const closeGuideModal = () => setModalGuideStatus(false);

	const parsed = queryString.parse(location.search);

	return (
		<>
			<div id="header">
				<div className={`top-bar${special_date ? ' sale' : ''}`}>
					<div className="top-bar__logo">
						<a href="https://fconline.garena.vn" className="logo">
							<img src="/images/logo.png" alt="" />
						</a>
					</div>
					<div className="top-bar__menu">
						<a
							href="https://fconline.garena.vn/bilac-fconline/"
							className={`menu-link${lib.isInGame() ? ' ingame' : ''}`}
							target="_blank"
						>
							Trang chủ
						</a>
						<a
							href="#"
							className="menu-link"
							onClick={(e) => {
								e.preventDefault();
								setModalGuideStatus(true);
							}}
						>
							Hướng dẫn
						</a>
						<a
							href="#"
							className="menu-link"
							onClick={(e) => {
								e.preventDefault();
								setModalStatus(true);
							}}
						>
							Lịch sử
						</a>
						<a
							href="https://napthe.vn/app/32837/buy/0"
							className={`menu-link${lib.isInGame() ? ' ingame' : ''}`}
							target="_blank"
						>
							Nạp thẻ
						</a>
					</div>
					<div className="top-bar__user">
						{!userId ? (
							<a
								href="/user/login"
								className={`btn-header btn-header--login${
									special_date ? ' sale' : ''
								}`}
								onClick={(e) => {
									if (!eventStarted) {
										e.preventDefault();
										lib.showMessage(config.demoMsg);
										return false;
									}
								}}
							>
								Đăng nhập
							</a>
						) : (
							<>
								<span className="user-info">
									HLV <strong className="nickname">{nickname}</strong>
								</span>
								{parsed.ingame != 1 && (
									<a
										href="/user/logout"
										className={`btn-header btn-header--logout${
											special_date ? ' sale' : ''
										}`}
									>
										Đăng xuất
									</a>
								)}
							</>
						)}
					</div>
				</div>
			</div>
			{modalStatus && (
				<ModalHistory
					userHistory={userHistory}
					isGettingHistory={isGettingHistory}
					getHistory={getHistory}
					voteHistory={voteHistory}
					matches={matches}
					modalStatus={modalStatus}
					closeModal={closeModal}
				/>
			)}
			{modalGuideStatus && (
				<ModalGuide
					modalStatus={modalGuideStatus}
					closeModal={closeGuideModal}
				/>
			)}
		</>
	);
};

export default Header;
